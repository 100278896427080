.profile-container {
    min-height: 70vh;
    display: flex;
  }
  
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .user-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
  
  .user-field {
    margin-bottom: 10px;
  }

  .profile-field input:disabled {
    background-color: #c4ccd3;
  }
  
  .profile-field input:not(:disabled) {
    background-color: #D8DFE4;
  }

  .profile-field textarea:disabled {
    background-color: #c4ccd3;
  }
  
  .profile-field textarea:not(:disabled) {
    background-color: #D8DFE4;
  }

  .badge-select{
    margin-top: 15px;
    margin-bottom: 15px;
  }
  
  
    @media (max-height: 852px) {
      .content-inner {
        margin-top: -250px;
      }
    }
  
  
  @media (max-width: 768px) {
      .profile-container {
        display: block;
      }
  
      .content-inner{
          margin-top: 0px;
      }
    }