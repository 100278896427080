@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');

/* Estilos para Navbar */
.navbar {
    background-color: #ffffff;
    border-bottom: 1px solid #ddd;
    height: 80px;
    color: #7800FF !important;
  }

  .dropdown-no-caret .dropdown-toggle::after {
    display: none;
  }

  .navbar-nav .dropdown-menu.show {
    background-color: #7800FF;
  }

  .navbar-brand {
    display: block;
    height: 50px;
    width: auto;
  }
  
  .navbar-brand img {
    height: 130%;
    width: auto;
    margin-right: 10px;
    margin-left: 15px;
  }

  .nav .nav-link.active {
    background-color: #300064;
    color: white;
  }
  
  .nav-link {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: #7800FF;
    text-decoration: none;
  }
  
  .nav-link:hover,
  .nav-link:focus {
    color: #7800FF;
    text-decoration: underline;
    background-color: transparent;
  }

  .avatar-container {
    display: flex;
    align-items: center;
  }
  
  .avatar-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid #7800FF;
  }

  /* Estilos para mobile */
  @media (max-width: 768px) {
    .navbar {
      height: auto;
    }
  
    .navbar-brand {
      margin-bottom: 10px;
    }
  
    .navbar-nav {
      margin-top: 10px;
    }

    .nav-link {
      color: #ffffff;
      font-weight: 500;
      font-size: 14px;
      text-decoration: none;
    }
  
    .nav-link:hover,
    .nav-link:focus {
      color: #ffffff;
      text-decoration: underline;
      background-color: transparent;
    }
  
    .navbar-toggler {
      color: #ffffff;
      border-color: #ffffff;
      margin-left: auto;
    }
  
    .navbar-toggler-icon {
      background-color: #ffffff !important;
    }

    #main-menu{
      background-color: white !important;
      padding: 5px;
      justify-content: flex-end !important;
      margin-right: 15px; /* Adjust the margin as needed */
    }
  }
  
  .navbar .ingresar-btn {
    background-color: #7800FF;
    border-radius: 20px;
    border: none;
    color: white !important;
    font-weight: bold;
    font-size: 16px;
    margin-left: 10px;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.3s ease-in-out;
    font-family: "TT Norms", sans-serif;
    font-weight: 700;
    font-size: 16px;
  }

  .navbar .ingresar-btn:hover {
    background-color: #2b0057 !important;
    color: white !important;
  }

  .custom-link {
    color: #7800FF;
    font-weight: bold;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    text-decoration: none;
    display: flex;
    justify-content: flex-end;
  }
  
  #my-navbar {
    background-color: none;
    border-bottom: 1px solid #ddd;
    height: 80px;
    display: flex;
    justify-content: center;
  }
  
  #my-navbar .nav-link {
    color: #7800FF;
    font-weight: bold;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    text-decoration: none;
    margin-left: 5px;
  }
  
  #my-navbar .nav-link:hover,
  #my-navbar .nav-link:focus {
    color: #7800FF;
    text-decoration: underline;
    background-color: transparent;
  }
  
  #my-navbar .navbar-toggler {
    color: #7800FF;
    border-color: #7800FF;
  }
  
  #my-navbar .navbar-toggler-icon {
    background-color: #ffffff !important;
  }

  #my-navbar #main-menu{
    background-color: white !important;
    padding: 5px;
    justify-content: flex-end !important;
    margin-right: 15px; /* Adjust the margin as needed */
  }
  
  #my-dropdown{
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    color: white;
    background-color: #7800FF;
  }