.sponsor-parent-container{
  margin-top: 5rem;
  min-height: 47vh;
}

.sponsor-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .sponsor-title{
    margin-bottom: 2rem;
    padding-top: 3rem; /* Ajusta este valor según sea necesario */
  }

.sponsor-title h2{
   font-family: "Open Sans Hebrew", sans-serif;
   font-size: 40px;
    font-weight: bold;
    color: #300064;
  }

  .sponsor-title h3{
    font-family: "Open Sans", sans-serif;
    font-size: 25px;
  }
  
  .carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    overflow-x: auto;
    padding: 0 20px;
  }
  
  .sponsor-cards {
    display: flex;
    flex-wrap: nowrap;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    gap: 20px;
  }
  
  .sponsor-card {
    flex: 0 0 auto;
    width: 30%;
    scroll-snap-align: start;
  }
  
  .carousel-button {
    background-color: transparent;
    border: none;
    font-size: 2rem;
    margin: 0 10px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
  }
  
  .carousel-button:hover {
    transform: scale(1.2);
  }
  
  .carousel-button:disabled {
    opacity: 0.5;
    cursor: default;
  }
  
  @media screen and (max-width: 768px) {

    .sponsor-parent-container{
      margin-top: 0rem;
      min-height: 40vh;
    }

    .sponsor-title h2 {
      font-size: 30px;
    }
    
    .sponsor-title h3 {
      font-size: 20px;
    }

    .sponsor-card {
      width: 30%;
    }

    .sponsor-cards {
        width: 80%;
      }

    .carousel {
      padding: 0;
    }
    .carousel-button {
      margin: 0 -5px;
    }

    .carousel-button img {
      width: 1rem; 
      height: auto;
    }
  }
  