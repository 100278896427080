footer {
    /* Agregamos un poco de padding para darle espacio al contenido */
    padding-top:5rem;
  
    /* Cambiamos el color de fondo */
    background-color: #f5f5f500;
  
    /* Centramos el texto */
    text-align: center;
  
    /* Hacemos que ocupe el ancho completo de la página */
    width: 100%;
    box-sizing: border-box;

      bottom: 0;
  }

  .logo-footer{
    width: 150px;
    height: 125px;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    margin: 0 auto;
  }

/* Estilo para los encabezados de columna */
footer h3 {
  /* Añadimos la fuente Open Sans en negrita */
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;

  /* Añadimos el color #300064 */
  color: #300064;

  /* Ajustamos el tamaño de fuente a 16px */
  font-size: 16px;
}

footer ul {
    list-style: none;
    padding-left: 0;
  }
  
  footer ul li {
    margin-bottom: 10px;
  }
  
  footer ul li a {
    color: #000000;
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    text-decoration: none;
  }
  
  footer ul li a:hover {
    text-decoration: underline;
  }

  .btn-outline-secondary.rounded-pill {
    background-color: #06C798;
    color: white;
    font-weight: bold;
    margin-bottom: 0.5rem;
}
  
  .btn-outline-secondary.rounded-pill:hover {
    background-color: #06C798;
    color: #fff;
  }
  
  .btn-outline-secondary.rounded-pill i {
    margin-right: 0.5rem;
    color: #300064;
  }

  footer p {
    display: flex;
    align-items: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #300064;
  }
  
  .icon-footer {
    width: 25px;
    height: 25px;
    margin-right: 5px;
  }

  .custom-button {
    display: block;
    margin: 0 auto;
    width: 180px;
    text-decoration: none;
  }
  
footer p, footer i {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #300064;
  }

  /* Estilos para pantallas de hasta 576px */
@media (max-width: 576px) {
    footer {
      padding: 0.5rem;

      padding-top: 4.5rem;
    }
  
    footer h3 {
      font-size: 14px;
    }
  
    .icon-footer {
      width: 20px;
      height: 20px;
    }
  }
  
  /* Estilos para pantallas de hasta 768px */
  @media (max-width: 768px) {
    footer h3 {
      font-size: 16px;
    }
  }
  
  /* Estilos para pantallas de hasta 992px */
  @media (max-width: 992px) {
    footer h3 {
      font-size: 18px;
    }
  }
  
  /* Estilos para pantallas de hasta 1200px */
  @media (max-width: 1200px) {
    footer h3 {
      font-size: 20px;
    }
  }
  