.signin-container{
  min-height: 100%;
  background-image: url("../../assets/worker level elemento27.png");
  padding-top: 0.5rem;
}

.contact-title{
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.contact-title h2{
    font-family: "Open Sans Hebrew", sans-serif;
    font-size: 40px;
     font-weight: bold;
     color: #300064;
}

.contact-title p{
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
  }

form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  input[type="text"],
  input[type="password"]{
    border-radius: 20px;
    background-color: #D8DFE4;
    padding: 0.5rem 5rem 0.2rem;
    border: none;
    margin-bottom: 0.5rem;
    width: 100%;
    text-align: center;
  }

  input[type="text"]::placeholder,
input[type="password"]::placeholder {
  color: #300064;
}

  .send-button {
    background-color: #4EA301;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 0.5rem 2rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-weight: bold;

  }

  .register-button {
    background-color: #06C798;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 0.5rem 2rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-weight: bold;
  }

  .signup-link {
    font-family: "Open Sans Hebrew", sans-serif;
    font-weight: bold;
    font-size: 18px;
    color: #300064;
    display: block;
    margin-bottom: 0.5rem;
  }
  
  .forgot-password-link {
    color: #7800FF;
    display: block;
    font-weight: bold;
    font-family: "Open Sans Hebrew", sans-serif;
    font-size: 18px;

  }
  
  .signup-link,
.forgot-password-link {
  text-decoration: none;
}

.signup-link:hover,
.forgot-password-link:hover {
  color: inherit;
}

.signin-hr {
  width: 25rem; /* Establecer la anchura del hr en un 50% */
  margin-top: 2rem; /* Agregar un margen superior de 2rem */
  margin-bottom: 2rem; /* Agregar un margen inferior de 2rem */
  margin-left: auto; /* Centrar horizontalmente el hr */
  margin-right: auto; /* Centrar horizontalmente el hr */
  border-top: 1px solid #ccc; /* Agregar una línea superior sólida */
  border-top-width: 5px;
}

  /* Para pantallas de hasta 576px de ancho */
  @media only screen and (max-width: 576px) {
    .signup-link {
      font-family: "Open Sans Hebrew", sans-serif;
      font-weight: bold;
      font-size: 18px;
      color: #300064;
      display: block;
      margin-bottom: 0.5rem;
      margin-left: 1rem;
    }
    
    .forgot-password-link {
      color: #7800FF;
      display: block;
      font-weight: bold;
      font-family: "Open Sans Hebrew", sans-serif;
      font-size: 18px;
      margin-left: 1rem;
    }

    .register-button{
      margin-left: 1rem;
    }

    .send-button{
      margin-left: 1rem;
    }
  }
  