.separator-container{
    background-color: #7800FF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 115px;
}

.separator-h2{
    color: white;
    text-align: center;
    font-family: "Open Sans Hebrew", sans-serif;
    font-size: 45px;
    font-weight: bold;
}
