.modal-dialog {
    max-width: 90%;
  }

  .modal-select{
    border-radius: 20px;
    background-color: #D8DFE4;
    padding: 0.5rem 5rem 0.2rem;
    border: none;
    margin-bottom: 0.5rem;
    width: 100%;
    text-align: center;
  }

input:disabled {
    background-color: #c4ccd3;
  }
  
input:not(:disabled) {
    background-color: #D8DFE4;
  }

textarea:disabled {
    background-color: #c4ccd3;
  }
  
textarea:not(:disabled) {
    background-color: #D8DFE4;
  }
  
  
  @media (min-width: 768px) {
    .modal-dialog {
      max-width: 700px;
    }
  }
  
  .modal-content {
    background-color: #fff;
    position: relative;
    border-radius: 0;
  }
  
  .modal-body {
    padding: 20px;
  }
  
  .modal-backdrop.show {
    opacity: 0.8;
  }
  
  .modal-backdrop {
    z-index: 1040;
  }
  