.vertical-hr{
    margin: 0 10px;
    width: 1px;
    height: 100%;
    background-color: #ccc;
    transform: rotate(90deg);
    padding-right: 60px;
    margin-top: 30px;
}

@media (max-width: 768px) {
    .vertical-hr {
        display: none;
    }
}