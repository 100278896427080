.carousel-container {
  margin: 0;
  padding: 0;
}

.carousel {
  margin: 0;
  overflow: hidden;
  position: relative;
}

.carousel-text {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    color: #300064;
    padding: 10% 5%;
    
  }

.carousel-text h1 {
    font-size: 65px;
    font-weight: 800;
    margin-bottom: 2rem;
    font-family: "MADE GoodTime Grotesk", sans-serif;
}

.carousel-item p {
    font-family: "Open Sans", sans-serif;
    font-size: 25px;
  }
  
  .carousel-text button {
    display: inline-block;
    border-radius: 20px;
    border: none;
    color: white !important;
    font-weight: bold;
    font-size: 16px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.3s ease-in-out;
    font-family: "TT Norms", sans-serif;
    font-weight: 800;
  }
  
  .carousel-item-container-2 {
    background-image: url("../../../assets/worker level elemento03.png");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
  
  .carousel-item-container-3 {
    background-image: url("../../../assets/worker level elemento05.png");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
  
  .carousel-item-container-4 {
    background-image: url("../../../assets/worker level elemento07.png");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
  
  .carousel-item-container-5 {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #f5f5f500;
  }

  .carousel-item-container-5 h1 {
    font-family: "Open Sans Hebrew", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 48px;
  }

  .carousel-item-container-5 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .carousel-item-container-5 button{
    display: inline-block;
    border-radius: 20px;
    border: none;
    color: white !important;
    font-weight: bold;
    font-size: 16px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.3s ease-in-out;
    font-family: "TT Norms", sans-serif;
    font-weight: 800;
  }

  .carousel-text-last{
    color: #300064;
    height: 50%;
}

.carousel-btn-last{
    display: flex;
    align-items: center;
    justify-content: center;

}

/* Para pantallas pequeñas */
@media (max-width: 576px) {


  .carousel-container{
    margin-top:5rem;
  }

  .carousel-text {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: flex-start;
    padding: 5% 10%;
    backdrop-filter: blur(10px);
    border-radius: 15px;
  }
  
  .carousel-text h1 {
    font-size: 30px;
    margin-bottom: 1rem;

  }
  
  .carousel-item p {
    font-size: 16px;
  }

  .carousel-item-container-5{
    height: 56vh;
  }

  .carousel-item-container-5 h1 {
    font-size: 30px;
    line-height: 36px;
  }

  .carousel-item-container-5 button{
    font-size: 14px;
    padding: 8px 16px;
  }
}

/* Para pantallas medianas */
@media (min-width: 576px) and (max-width: 768px) {
  .carousel-text {
    width: 100%;
    height: 50%;
    justify-content: center;
    align-items: flex-start;
    padding: 5% 10%;
  }
  
  .carousel-text h1 {
    font-size: 40px;
    margin-bottom: 1rem;
  }
  
  .carousel-item p {
    font-size: 20px;
  }
  
  .carousel-item-container-5 h1 {
    font-size: 36px;
    line-height: 44px;
  }

  .carousel-item-container-5 button{
    font-size: 16px;
    padding: 10px 20px;
  }
}

.carousel-control-prev-icon{
  display: none !important;
}

.carousel-control-next-icon{
  display: none !important;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box !important;
  flex: 0 1 auto !important;
  width: 10px !important;
  height: 10px !important;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity .6s ease;
  border-radius: 100% !important;
}