.contact-title{
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.contact-title h2{
    font-family: "Open Sans Hebrew", sans-serif;
    font-size: 30px;
     font-weight: bold;
     color: #300064;
}

.contact-title p{
    font-family: "Open Sans", sans-serif;
  }

form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  input[type="text"],
  input[type="email"],
  textarea {
    border-radius: 20px;
    background-color: #D8DFE4;
    padding: 0.5rem 5rem 0.2rem;
    border: none;
    margin-bottom: 0.5rem;
    width: 100%;
    text-align: center;
  }

  input[type="text"]::placeholder,
input[type="email"]::placeholder,
textarea::placeholder {
  color: #300064;
}

  button {
    background-color: #4EA301;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 0.5rem 2rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  
  .contact-hr {
    width: 30%; /* Establecer la anchura del hr en un 50% */
    margin-top: 2rem; /* Agregar un margen superior de 2rem */
    margin-bottom: 2rem; /* Agregar un margen inferior de 2rem */
    margin-left: auto; /* Centrar horizontalmente el hr */
    margin-right: auto; /* Centrar horizontalmente el hr */
    border-top: 1px solid #ccc; /* Agregar una línea superior sólida */
    border-top-width: 5px;
  }
  
  .contact-bubbles {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    text-align: center;
    max-width: 600px; /* o el ancho máximo que desees */
    margin: 0 auto; /* para centrar horizontalmente */
  }
  
  .contact-bubble {
    position: relative;
    background-color: #300064;
    border-radius: 25%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    padding: 3rem 0rem;
}

  .contact-bubble h6{
    margin-bottom: -2px;
  }

  .contact-bubble-icon {
    position: absolute;
    top: -5px;
    left: -5px;
    display: inline-block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 10px;
    text-align: center;
    line-height: 25px;
  }
  
  @media (max-width: 767px) {
    .contact-bubbles {
        display: grid;
        grid-template-rows: repeat(2, 1fr);
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1rem;
        text-align: center;
        max-width: 600px; /* o el ancho máximo que desees */
        margin: 0 auto; /* para centrar horizontalmente */
      }
      
  }
  
  /* Para pantallas de hasta 576px de ancho */
  @media only screen and (max-width: 576px) {
    .contact-bubbles {
        display: grid;
        grid-template-rows: repeat(2, 1fr);
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1rem;
        text-align: center;
        max-width: 600px; /* o el ancho máximo que desees */
        margin: 0 auto; /* para centrar horizontalmente */
      }
  }
  