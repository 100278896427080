.sponsor-card {
    background-color: #fff;
    border: 1px solid #e5e5e5;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
    padding: 20px;
    font-family: "Open Sans", sans-serif;
    border-radius: 50%;
    border: 6px solid #7800FF;
  }
  
  .sponsor-card h3 {
    color: #300064;
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
  
  .sponsor-card h4 {
    color: #300064;
    font-size: 1.1rem;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .sponsor-card p {
    font-size: 1rem;
    margin-bottom: 10px;
  }

  .sponsor-card img {
    width: 80%;
    height: auto;
    display: block;
    margin: 0 auto;
    margin-top: 10px;
    border-radius: 5%;
    }
  
  .sponsor-card p:last-of-type {
    margin-bottom: 0;
  }
  
  @media screen and (max-width: 768px) {
    .sponsor-card {
    width: 50%;
    margin: 0 auto 20px;
    }
    
    .sponsor-card h3 {
    font-size: 1rem;
    margin-bottom: 3px;
    }
    
    .sponsor-card h4 {
    font-size: 0.9rem;
    margin-bottom: 5px;
    }
    
    .sponsor-card p {
    font-size: 0.8rem;
    margin-bottom: 5px;
    }

    .sponsor-card img {
      width: 90%;
      margin-top: 10px;
    }
    }