/* EmployeeSummary.css */

.employee-summary {
  width: 100%;
  margin: 0 auto;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: 'Open Sans Hebrew', sans-serif;
}

.experience {
  margin-bottom: 20px;
  margin: 10px 10px;
}

.company-name {
  font-size: 16x;
  margin-bottom: 5px;
}

.description-name{
  font-size: 14px;
  margin-bottom: 5px;
}

.date-range {
  font-size: 12px;
}

.company {
  margin-bottom: 20px;
}

.company-info {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 10px;
}

.company-description {
  font-size: 14px;
  margin-top: 5px;
}

.course {
  margin-bottom: 20px;
  margin: 10px 10px;
}

.course-description {
  font-size: 14px;
  margin-top: 5px;
}

.section{
  border-radius: 8px;
  background-color: #D8DFE4;
  min-height: 35vh;
  align-items: center;
  max-height: 35vh;
  overflow-y: auto;
  margin-bottom: 20px;
}

.section::-webkit-scrollbar {
  width: 8px;
}

.section::-webkit-scrollbar-thumb {
  background-color: #300064;
  border-radius: 8px;
}

.section::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}


.avatar-image-summary {
  width: 70px;
  height: 70px;
  margin-right: 10px;
  border: 1px solid #7800FF;
}


.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profile-picture img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.employee-details {
  margin-left: 20px;
}

.employee-details h2 {
  margin: 0;
  font-size: 24px;
}

.content {
  margin-top: 20px;
}

.content h3 {
  margin-top: 30px;
  margin-bottom: 15px;
  font-size: 20px;
}

.experience,
.company,
.course {
  margin-bottom: 20px;
}

.experience h4,
.company h4,
.course h4 {
  margin: 0;
  font-size: 18px;
}

.company-image img {
  width: 100%;
  max-width: 200px;
  height: auto;
  border-radius: 4px;
  object-fit: cover;
}

.attitudes-list,
.job-categories-list {
  list-style-type: none;
  padding: 0;
  margin: 10px 10px;
}

.attitudes-list li,
.job-categories-list li {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #7800ff;
  color: #ffffff;
  font-weight: bold;
}

.additional-info p {
  margin-bottom: 5px;
}

.rating-star-container{
  display: inline-block;
}

.company-rating {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Espacio entre calificaciones */
}

.attitude-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.attitude-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px; /* Espacio entre actitudes */
}

.attitude-item > .attitude-badge {
  /* Estilos para el badge si lo necesitas */
  margin-right: 5px; /* Espacio entre badge y RatingStars */
}


@media (max-width: 768px) {
  .row{
    flex-direction: column;
  }

  .attitudes-list li,
  .job-categories-list li {
    margin-right: 5px;
    font-size: 14px;
  }

  .section{
    min-height: 25vh;
    max-height: 26vh;
  }

  .section::-webkit-scrollbar {
    width: 6px;
  }

}