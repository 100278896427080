.welcome-message {
    padding: 10px 70px;
    cursor: pointer;
    font-family: "Open Sans Hebrew", sans-serif;
    font-weight: bold;
    margin-top: 35px;
  }

  .content{
    font-weight: lighter;
  }
  
  .welcome-message .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .welcome-message .arrow {
    transition: transform 0.3s ease;
  }
  
  .welcome-message.open .arrow {
    transform: rotate(180deg);
  }
  
  .welcome-message .content {
    margin-top: 10px;
  }

  /* media queries for min 765 */
  @media (min-width: 765px) {
    .welcome-message {
      padding: 10px 100px;
      margin-top: 100px;
    }


  }