.membership-container {
  min-height: 70vh;
  display: flex;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.benefits-h1{
    font-family: "Open Sans Hebrew", sans-serif;
    font-weight: bold;
    color: #4E257B;
}

.benefits-p h5{
    font-family: "Open Sans Hebrew", sans-serif;
    font-size: 1.1rem;
}

.benefits-p img{
    margin-right: 0.5rem;
    width: 2rem;
    height: 2rem;
}

.table-container {
    overflow-x: auto;
  }
  

.table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .payment-input-container {
    display: flex;
    align-items: center;
  }
  
  .payment-input-container input {
    padding: 8px;
    margin-right: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .payment-input-container button {
    padding: 8px 16px;
    background-color: #7800ff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .payment-input-container button:hover {
    background-color: #4E257B;
  }

@media (max-width: 768px) {
    .membership-container {
      display: block;
    }

    .benefits-p h5{
        font-family: "Open Sans Hebrew", sans-serif;
        font-size: 1rem;
    }

    .benefits-p img{
        width: 1.5rem;
        height: 1.5rem;
    }

    .payment-input-container {
        flex-direction: column;
        align-items: flex-start;
      }
      
      .payment-input-container input {
        margin-bottom: 10px;
      }
      
      .payment-input-container button {
        margin-top: 10px;
      }


  }