.signin-container{
  min-height: 100%;
  background-image: url("../../assets/worker level elemento27.png");
  padding-top: 0.5rem;
}

.contact-title{
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.contact-title h2{
    font-family: "Open Sans Hebrew", sans-serif;
    font-size: 40px;
     font-weight: bold;
     color: #300064;
}

.contact-title p{
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
  }

form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  input[type="text"],
  input[type="password"],
  input[type="date"],
  select{
    border-radius: 20px;
    background-color: #D8DFE4;
    padding: 0.5rem 5rem 0.2rem;
    border: none;
    margin-bottom: 0.5rem;
    width: 100%;
    text-align: center;
  }

  input[type="text"]::placeholder,
input[type="password"]::placeholder,
input[type="date"]::placeholder,
select::placeholder {
  color: #300064;
}

  .send-button {
    background-color: #4EA301;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 0.5rem 2rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-weight: bold;

  }

  .register-button {
    background-color: #06C798;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 0.5rem 2rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-weight: bold;
  }

  .signup-link {
    font-family: "Open Sans Hebrew", sans-serif;
    font-weight: bold;
    font-size: 18px;
    color: #300064;
    display: block;
    margin-bottom: 0.5rem;
  }
  
  .forgot-password-link {
    color: #7800FF;
    display: block;
    font-weight: bold;
    font-family: "Open Sans Hebrew", sans-serif;
    font-size: 18px;

  }
  
  .signup-link,
.forgot-password-link {
  text-decoration: none;
}

.signup-link:hover,
.forgot-password-link:hover {
  color: inherit;
}

.signin-hr {
  width: 25rem; /* Establecer la anchura del hr en un 50% */
  margin-top: 2rem; /* Agregar un margen superior de 2rem */
  margin-bottom: 2rem; /* Agregar un margen inferior de 2rem */
  margin-left: auto; /* Centrar horizontalmente el hr */
  margin-right: auto; /* Centrar horizontalmente el hr */
  border-top: 1px solid #ccc; /* Agregar una línea superior sólida */
  border-top-width: 5px;
}

.date-input-container {
  position: relative;
}

.date-input-container input[type="date"] {
  padding: 0.5em;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.date-input-container label {
  position: absolute;
  top: 0.5em;
  left: 1em;
  color: #777;
  pointer-events: none;
  transition: top 0.3s ease, font-size 0.3s ease;
}

.date-input-container input[type="date"]:focus + label,
.date-input-container input[type="date"]:not(:placeholder-shown) + label {
  top: 0.8em;
  font-size: 0.8em;
  color: #300064;
}


  /* Para pantallas de hasta 576px de ancho */
  @media only screen and (max-width: 576px) {
    .signup-link {
      font-family: "Open Sans Hebrew", sans-serif;
      font-weight: bold;
      font-size: 18px;
      color: #300064;
      display: block;
      margin-bottom: 0.5rem;
      margin-left: 1rem;
    }
    
    .forgot-password-link {
      color: #7800FF;
      display: block;
      font-weight: bold;
      font-family: "Open Sans Hebrew", sans-serif;
      font-size: 18px;
      margin-left: 1rem;
    }

    .register-button{
      margin-left: 1rem;
    }

    .send-button{
      margin-left: 1rem;
    }
      input[type="text"],
      input[type="password"],
      input[type="date"],
      input[type="email"]
      select {
        border-radius: 10px; /* reducimos el radio de borde */
        padding: 0.3rem 1rem; /* reducimos el padding */
        margin-bottom: 0.3rem; /* reducimos el margin bottom */
      }

      div.checkbox-container {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      
      div.checkbox-container label {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 1rem;
      }
      
      div.checkbox-container label input {
        margin-right: 0.5rem;
      }

  }
  
  .toast-position{
    top: 25rem !important;
  }