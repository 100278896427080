.profile-container {
    min-height: 70vh;
    display: flex;
  }
  
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
  }  

  .table-container {
    overflow-x: auto;
  }
  
  .table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .toggle-button {
    cursor: pointer;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    color: #fff;
  }
  
  .toggle-button.active {
    background-color: green;
  }
  
  .toggle-button.inactive {
    background-color: red;
  }

  @media (max-width: 768px) {
      .profile-container {
        display: block;
      }
  
    }