.static-item-container-5 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #f5f5f500;
  }

.static-item-container-5 h1 {
    font-family: "Open Sans Hebrew", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 48px;
  }

  .static-item-container-5 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .static-item-container-5 button{
    display: inline-block;
    border-radius: 20px;
    border: none;
    color: white !important;
    font-weight: bold;
    font-size: 16px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.3s ease-in-out;
    font-family: "TT Norms", sans-serif;
    font-weight: 800;
  }

    
/* Para pantallas pequeñas */
@media (max-width: 576px) {

    .static-item-container-5{
      height: 45vh;
    }
  
    .static-item-container-5 h1 {
      font-size: 30px;
      line-height: 36px;
      text-align: center;
    }
  
    .static-item-container-5 button{
      font-size: 14px;
      padding: 8px 16px;
    }
  }
  
  /* Para pantallas medianas */
  @media (min-width: 576px) and (max-width: 768px) {
    
    .static-item-container-5 h1 {
      font-size: 36px;
      line-height: 44px;
    }
  
    .static-item-container-5 button{
      font-size: 16px;
      padding: 10px 20px;
    }
  }