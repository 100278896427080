.filters {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    background-color: white;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .filters > * {
    margin-right: 10px;
  }
  
  .filters button {
    margin-top: 5px;
  }

  #filter-input .form-control input:not(:disabled){
    background-color: white !important;
    margin-bottom: 8px !important;
  }

  td {
    vertical-align: middle;
  }
  

  /* Estilos para dispositivos móviles */
@media (max-width: 576px) {
    .filters {
      flex-wrap: wrap;
      justify-content: center;
    }
  
    .filters > * {
      margin-bottom: 10px;
      width: 100%;
    }
  
    .filters button {
      margin-top: 10px;
    }
  }
  