.modal-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .modal-image {
    max-width: 100%;
    max-height: 300px;
  }
  