.benefits-carousel-container {
  margin: 0;
  padding: 0;
}

.benefits-carousel {
  margin: 0;
  overflow: hidden;
  position: relative;
}

.benefits-carousel-text {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 55%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    color: #300064;
    padding: 10% 5%;
  }

.benefits-carousel-text .benefits-h1{
    font-size: 40px;
    margin-bottom: 2rem;
    font-family: "Open Sans Hebrew", sans-serif;
    font-weight: bold;
}

.benefits-carousel-item .benefits-p {
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    color: #000000 !important;
  }
  
  .benefits-carousel-text button {
    display: inline-block;
    border-radius: 20px;
    border: none;
    color: white !important;
    font-weight: bold;
    font-size: 16px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.3s ease-in-out;
    font-family: "TT Norms", sans-serif;
    font-weight: 800;
  }

/* Para pantallas pequeñas */
@media (max-width: 576px) {
  .benefits-carousel-text {
    width: 100%;
    height: 50%;
    justify-content: center;
    align-items: flex-start;
    padding: 60% 10%;

    background-color: rgba(255, 255, 255, 0.5); /* Fondo semi-transparente */
    backdrop-filter: blur(3px); /* Efecto de desenfoque */
    border-radius: 10px; /* Bordes redondeados */
    
  }
  
  .benefits-carousel-text h1 {
    font-size: 30px;
    margin-bottom: 1rem;
  }
  
  .benefits-carousel-item p {
    font-size: 16px;
  }
}

/* Para pantallas medianas */
@media (min-width: 576px) and (max-width: 768px) {
  .benefits-carousel-text {
    width: 100%;
    height: 50%;
    justify-content: center;
    align-items: flex-start;
    padding: 5% 10%;
  }
  
  .benefits-carousel-text h1 {
    font-size: 40px;
    margin-bottom: 1rem;
  }
  
  .benefits-carousel-item p {
    font-size: 20px;
  }
}

@media (max-height: 800px) {
  .benefits-carousel-container {
    margin-top: 2rem;
  }

  .benefits-carousel-text {
    margin-bottom: 1rem;
  }

  .benefits-p {
    padding: 0 1rem;
  }

  .benefits-img {
    height: auto;
    max-height: 50vh;
    margin: 0 auto;
  }
}