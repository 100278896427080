.faq-container{
    min-height: 80vh;
    background-image: url("../../assets/worker level elemento41.png");
    padding-top: 0.5rem;
}

.faq-title{
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.faq-title h2{
    color:#300064;
    font-family: 'Open Sans Hebrew', sans-serif;
    font-weight: bold;
    font-size: 40px;
}

.faq-title p{
    color:black;
    font-family: 'Open Sans Hebrew', sans-serif;
    font-size: 20px;
}


.faq-item {
    border-radius: 50px;
    overflow: hidden;
    margin-bottom: 1rem;
    width: 50%;
  }
  
  .faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #f2f2f2;
    cursor: pointer;
    background-color: #300064;
    color: white;
  }
  
  .faq-answer {
    padding: 1rem;
    max-height: 0;
    overflow: hidden;
    background-color: #300064;
    color: #fff;
    border-radius: 0 0 50px 50px;
    display: none;
    transition: height 0.3s ease-in-out;
  }
  
  .faq-answer.active {
    max-height: 100vh;
    display: block;
  }
  
  .faq-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  #faq-button {
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 1.5rem;
    cursor: pointer;
    margin-left: 1rem;
    color: white;
  }
  
  #faq-button.active {
    transform: rotate(45deg);
  }