.employee-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .employee-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  
  .employee-image {
    width: 150px;
    height: 150px; 
    object-fit: cover; 
    border-radius: 50%; 
  }  