.content-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-top: -280px;
  }

  .dashboard-container {
  min-height: 70vh;
  display: flex;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

  @media (max-height: 852px) {
    .content-inner {
      margin-top: -250px;
    }
  }


@media (max-width: 768px) {
    .dashboard-container {
      display: block;
    }

    .content-inner{
        margin-top: 0px;
    }
  }