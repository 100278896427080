.contact-title{
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.contact-title h2{
    font-family: "Open Sans Hebrew", sans-serif;
    font-size: 30px;
     font-weight: bold;
     color: #300064;
}

.contact-title p{
    font-family: "Open Sans", sans-serif;
  }

form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  input[type="text"],
  input[type="email"],
  textarea {
    border-radius: 20px;
    background-color: #D8DFE4;
    padding: 0.5rem 5rem 0.2rem;
    border: none;
    margin-bottom: 0.5rem;
    width: 100%;
    text-align: center;
  }

  input[type="text"]::placeholder,
input[type="email"]::placeholder,
textarea::placeholder {
  color: #300064;
}

  button {
    background-color: #4EA301;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 0.5rem 2rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }