.badge-owner {
    background-color: #4EA301; /* Rojo */
  }

  .badge-company {
    background-color: #06C798; /* Azul */
  }

  .badge-employee {
    background-color: #7800FF; /* Verde */
  }

  .badge-freelance {
    background-color: #C101FA; /* Amarillo */
  }

  .export-csv-button {
    background-color: #3498db;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .export-csv-button:hover {
    background-color: #2980b9;
  }