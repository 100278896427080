.comment-content {
    /* Estilos para la vista de escritorio */
    max-height: 30px;
    overflow: hidden;
    position: relative;
  }
  
  .comment-content.collapsed {
    /* Estilos para la vista de escritorio */
    max-height: 50px;
  }
  
  .comment-content::after {
    /* Estilos para la vista de escritorio */
    content: '...';
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: white;
  }
  
  /* Media query para la vista móvil */
  @media (max-width: 767px) {
    .comment-content {
      /* Estilos para la vista móvil */
      max-height: 30px;
    }
  
    .comment-content.collapsed {
      /* Estilos para la vista móvil */
      max-height: 30px;
    }
  }
  