.star-rating {
    display: inline-flex;
    align-items: center;
    gap: 4px;
  }
  
  .qualification-star {
    cursor: pointer;
    font-size: 3rem;
    color: #d3d3d3;
  }
  
  .qualification-star.active,
  .qualification-star.hover {
    color: yellow;
  }
  