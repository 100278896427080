.custom-sidebar {
  color: #7800ff;
  font-family: "Open Sans Hebrew", sans-serif;
  font-weight: bold;
  font-size: 20px;
}

.pro-inner-item:hover{
  color: #7800ff !important;
  font-size: 20px;
}

.pro-sidebar-inner{
  background:  #d8dfe4 !important;
  min-height: 70vh;
  border-radius: 8px;
}

.pro-sidebar .pro-menu a{
  text-decoration: none;
  color: #7800ff;
}

.pro-sidebar .pro-menu a:hover{
  text-decoration: none;
  color: #7800ff;
}

.help-button {
  border-radius: 50px;
  background-color: #7800ff;
  text-align: center;
  color: white;
}

.help-button-container {
  width: 40%;
}


@media (max-width: 768px) {
    .pro-sidebar.md {
      position: fixed;
      left: 0;
      max-height: calc(70vh - 60px);
      overflow-y: auto;
    }
  
    .pro-sidebar.md.collapsed {
      display: block !important;
      left: 0;
    }
  }

