.content-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-top: -280px;
  }

  .qr-container {
  min-height: 70vh;
  display: flex;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.qr-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header {
    margin-bottom: 10px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
  
  .small-text {
    font-size: 12px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }

  .foot-qr{
    font-weight: bold;
    font-size: 20px;
  }

  @media (max-height: 852px) {
    .content-inner {
      margin-top: -250px;
    }
  }


@media (max-width: 768px) {
    .qr-container {
      display: block;
    }

    .content-inner{
        margin-top: 0px;
    }
  }