.privacy-policy-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 5rem;
    max-width: 90%;
    margin: auto;
}

.privacy-policy-title{
    font-family: "Open Sans Hebrew", sans-serif;
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    color: #300064;
}

.privacy-policy-content {
    overflow-y: scroll;
    max-height: 70vh;
    border: none;
    padding: 20px;
    width: 100%;

  }
  
  .privacy-policy-content::-webkit-scrollbar {
    width: 12px;
  }
  
  .privacy-policy-content::-webkit-scrollbar-thumb {
    background-color: #300064;
    border-radius: 8px;
  }
  
  .privacy-policy-content::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }