.white-static-text {
    position: absolute;
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    color: #300064;
    padding: 10% 5%;
  }

  .white-static-text h1 {
    font-size: 65px;
    font-weight: 800;
    margin-bottom: 2rem;
    font-family: "MADE GoodTime Grotesk", sans-serif;
}

.carousel-item p {
    font-family: "Open Sans", sans-serif;
    font-size: 25px;
  }
  
  .white-static-text button {
    display: inline-block;
    border-radius: 20px;
    border: none;
    color: white !important;
    font-weight: bold;
    font-size: 16px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.3s ease-in-out;
    font-family: "TT Norms", sans-serif;
    font-weight: 800;
  }

  
/* Para pantallas pequeñas */
@media (max-width: 576px) {
    .white-static-text {
      width: 100%;
      height: 65%;
      justify-content: center;
      align-items: flex-start;
      padding: 5% 10%;
      background-color: rgba(255, 255, 255, 0.5); /* Fondo semi-transparente */
      backdrop-filter: blur(8px); /* Efecto de desenfoque */
      border-radius: 10px; /* Bordes redondeados */
    }
    
    .white-static-text h1 {
      font-size: 30px;
      margin-bottom: 1rem;
    }
    
    .carousel-item p {
      font-size: 16px;
    }
  
    .carousel-item-container-5{
      height: 56vh;
    }
  
    .carousel-item-container-5 h1 {
      font-size: 30px;
      line-height: 36px;
    }

    .white-static-text button{
      font-size: 14px;
      padding: 8px 16px;
      margin-bottom: 4px;
    }

    .white-static-text .buttons{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .white-static-text .buttons button {
      flex: 1;
      width: 100%;
    }
  }
  
  /* Para pantallas medianas */
  @media (min-width: 576px) and (max-width: 768px) {
    .white-static-text {
      width: 100%;
      height: 50%;
      justify-content: center;
      align-items: flex-start;
      padding: 5% 10%;
    }
    
    .white-static-text h1 {
      font-size: 40px;
      margin-bottom: 1rem;
    }
    
    .carousel-item p {
      font-size: 20px;
    }
    
    .carousel-item-container-5 h1 {
      font-size: 36px;
      line-height: 44px;
    }
  
    .carousel-item-container-5 button{
      font-size: 16px;
      padding: 10px 20px;
    }
  }